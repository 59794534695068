<!--이따 핫티스트 매칭 관리 수료 팝업에서도 사용하도록 변경 필요-->
<template>
	<div class="flex">
		<div class="selectListWrap ml-4">
			<div
				v-for="(v, i) in input.selectedItems"
				:class="i === input.moveTarget ? 'selected' : ''"
				class="selectListItem p-2"
				@click="input.moveTarget = i"
			>
				{{ i + 1 }}. {{ v.label }}
				<b-button
					class="ml-2 float-right -top-1"
					@click.stop="selectedListHandle(v, false)"
					size="sm"
					variant="no"
				>
					<b-icon font-scale="1" icon="trash"/>
				</b-button>
			</div>
			<h6 class="w-1/1 text-center absolute bottom-0">최대 {{ maxLength }}개 선택 후 순위를 지정하세요.</h6>
		</div>
		<div class="w-8 ml-2 verticalCenter">
			<b-button class="mb-4" @click="moveItem(false)" size="sm" variant="no">
				<b-icon font-scale="1" icon="caret-up"/>
			</b-button>
			<b-button class="" @click="moveItem(true)" size="sm" variant="no">
				<b-icon font-scale="1" icon="caret-down"/>
			</b-button>
		</div>


		<div class="selectListWrap">
			<b-form-input
				class="float-right"
				v-model.trim="input.searchText"
				name="selectListSearchText"
				:placeholder="`${title} 검색`"
				@keyup="search"
			/>

			<div id="selectableListWrap">
				<p
					v-for="v in list"
					v-show="v.isRes && !v.isSelected"
					:class="v == input.selectTarget ? 'selected' : ''"
					class="selectListItem p-2"
					@click="selectItem(v, true)"
				>
					{{ v.label }} / {{ v.value }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
const defInput = {
	searchText: '',
	selectedItems: [],
	selectTarget: '',
	moveTarget: '',
}

export default {
	beforeCreate() {
		this.dName = 'selectList'
	},

	props: {
		itemList: { default: [] },
		maxLength: { default: 3 },
		title: { default: '상품명' },
	},
	data() {
		return {
			list: {},
			listCt: 0,

			isEdited: false,
			input: { ...defInput },
		}
	},
	methods: {
		init( selectedList = [] ) {
			//selectedList: 기존에 미리 선택된 아이템(카테고리) value값
			this.input.selectedItems.splice( 0, this.maxLength )
			this.input.selectTarget = ''
			this.input.moveTarget = ''

			this.isEdited = false

			this.itemList.map( v => ( v.isSelected = false ) )
			selectedList.map( v => {
				const selectedItem = this.list.filter( l => l.value == v )
				if( selectedItem.length ) this.selectedListHandle( selectedItem[ 0 ], true, true )
			} )
		},
		search() {
			const search = this.input.searchText.split( ' ' )
			this.list.map( ( v, i ) => {
				let isRes = true
				search.map( s => {
					if( s && v.label.indexOf( s ) == -1 ) isRes = false
				} )
				v.isRes = isRes
				this.$set( this.list, i, v )
			} )
		},
		selectedListHandle( item, isInsert = true, isInit = false ) {
			//선택 목록에 넣을지 뺄지 컨트롤
			//item: 선택한 항목 객체
			//isInsert: 선택 항목에 추가하는 거면 true, 제거하는 거면 false
			//isInit: 제일 처음에 기존에 저장된 값을 넣어줄 때 true
			if( isInsert && this.input.selectedItems.length >= this.maxLength ) {
				alert.w( this.maxLength + '개까지 선택 가능합니다.' )
			}
			else {
				if( isInsert ) {
					const filter = this.insertFilter( item, this.input.selectedItems )
					if( filter === true ) {
						item.isSelected = true
						this.input.selectedItems.push( item )
						this.input.selectTarget = ''
					}
					else alert.w( filter )
				}
				else {
					item.isSelected = false
					this.input.selectedItems.splice( this.input.selectedItems.indexOf( item ), 1 )
					this.input.moveTarget = ''
				}
				if( !isInit ) this.isEdited = true
			}
		},
		moveItem( isDown = true ) {
			let selectIdx = this.input.moveTarget
			if( selectIdx !== '' ) {
				if( isDown ) {
					if( selectIdx == this.input.selectedItems.length - 1 ) return
				}
				else if( !selectIdx ) return

				const nextIdx = isDown ? selectIdx + 1 : selectIdx - 1,
					beforeItem = this.input.selectedItems[ nextIdx ]

				this.$set( this.input.selectedItems, nextIdx, this.input.selectedItems[ selectIdx ] )
				this.$set( this.input.selectedItems, selectIdx, beforeItem )
				this.input.moveTarget = nextIdx
				this.isEdited = true
			}
		},
		selectItem( item ) {
			if( this.input.selectTarget == item ) this.selectedListHandle( item, true )
			else this.input.selectTarget = item
		},
		getSelectedItem( filter ) {
			let res = this.input.selectedItems
			if( filter ) {
				if( typeof filter == 'function' ) res = res.filter( v => filter( v ) )
				else res = res.map( v => v[ filter ] )
			}
			return res
		},

		insertFilter( item, selectedItems ) {
			//현재까지는 카테고리만 있어서 여기 추가하는데 이따가 다른 거 포함되면 따로 빼야 된다
			if( selectedItems.length ) {
				if( item.value == 'Z01' || selectedItems.filter( v => v.value == 'Z01' ).length )
					return "'기타' 카테고리는 1순위에 단독으로만 등록할 수 있습니다."
			}
			return true
		},
	},
	mounted() {
		const res = this.itemList
		if( res[ 0 ] ) if( res[ 0 ].label == '전체' || !res[ 0 ].value ) res.splice( 0, 1 )
		this.list = res.map( v => {
			v.isRes = true
			v.isSelected = false
			return v
		} )
		this.listCt = res.length
	},
}
</script>

<style>
.selectListWrap {
	width: 45%;
	height: 14rem;
	border: 1px solid var(--gray);
	border-radius: 5px;
}

.selectListWrap #selectableListWrap {
	width: 100%;
	height: calc(100% - 2.3rem);
	overflow-y: scroll;
}

.selectListWrap .selectListItem {
	cursor: pointer;
	user-select: none;
}

.selectListWrap .selectListItem.selected,
.selectListWrap .selectListItem.moveTarget {
	color: var(--primary) !important;
	background-color: rgba(40, 199, 111, 0.12);
	border: rgba(40, 199, 111, 0.1) 1px solid;
}
</style>
