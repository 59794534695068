<template>
	<div>
		<b-card>
			<div class="flex">
				<p class="w-1/3">카페24 상품번호</p>
				<input class="form-control -mt-1.5" v-model="input['cafe24ProductNum']" placeholder="" type="number" required/>
				<div class="w-40 ml-3 px-4"></div>
			</div>
			<div class="flex mt-4">
				<p class="w-1/3">네이버 검색 키워드</p>
				<input class="form-control -mt-1.5" v-model="input['keyword']" placeholder="" type="text" required/>
				<b-button class="w-40 ml-3 px-4" @click="searchKeyword">검색</b-button>
			</div>
		</b-card>
		<selectList :itemList="res" :pr="ths"/>
	</div>
</template>

<script>

import { postApi } from "libs/axios";
import relateProductSelectList from "pages/relateProductSelectList";

let ths
export default {
	components: { selectList },
	data() {
		return {
			input: {
				cafe24ProductNum: '',
				keyword: '',
			},
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		searchKeyword() {
			if(!this.input.keyword)
				return alert.w('키워드를 입력해주세요')
			postApi( 'naver/shoppingSearch/' + encodeURI( this.input.keyword ) ).then(v=>{
				ths.res = v
			})
		},
	},
	created() {
		ths = this
		//postApi( 'member/t' );
		//this.search()
	},
}
</script>
